<template>
  <window-content v-if="!loading && !errors && !success">
    <div class="m-t">
      <e-row class=" erp-row-col-md">
        <e-col style="max-width: 240px">
          <erp-s-field
              view="lr"
              label="ID:"
              label-width="110px"
          >
            <erp-input simple-border v-model="usuario.id" disable />
          </erp-s-field>
        </e-col>

        <e-col style="max-width: 230px">
          <erp-s-field
              view="lr"
              label="Login:"
              label-width="50px"
          >
            <apelido-input :gerar="false" v-model="usuario.username" />
          </erp-s-field>
        </e-col>

        <e-col class="font-11" style="max-width: 260px; line-height: 20px">
          <erp-select
              v-model="usuario.active"
              :options="[
                {label: 'Ativo', value: true, color: 'positive'},
                {label: 'Inativo', value: false, color: 'negative'}
              ]"
          />
          <!--<div v-if="usuario.active" class="text-positive">Este usuário está ativo</div>
          <div v-else class="text-negative">Acesso inativo</div>-->
        </e-col>

      </e-row>

      <e-row class=" erp-row-col-md">
        <e-col>
          <erp-s-field
              view="lr"
              label="Nome completo:"
              label-width="110px"
          >
            <erp-input simple-border v-model="usuario.person.name" />
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row class=" erp-row-col-md">
        <e-col>
          <erp-s-field
              view="lr"
              label="E-mail:"
              label-width="110px"
          >
            <erp-input simple-border v-model="usuario.email" />
          </erp-s-field>
        </e-col>

      </e-row>
    </div>
    <erp-tabs @change="changeTab" :active="tabActive">
      <erp-tab-item name="principal">Permissões</erp-tab-item>
      <erp-tab-item name="logs">Logs</erp-tab-item>
    </erp-tabs>
    <window-body class="font-11" :class="{'flex col-grow': tabActive === 'logs' || tabActive === 'arremates'}" tabs>
      <div v-show="tabActive === 'principal'" class="wrapper m-t">
        <e-row class="m-t-sm">
          <e-col>
            <label class="flex items-center m-b-xs">
              <u-radio v-model="model.admin" :val="true" class="m-r-xs" />
              Administrador</label>
            <label class="flex items-center">
              <u-radio v-model="model.admin" :val="false" class="m-r-xs" />
              Limitado</label>
            <div v-if="!model.admin" class="font-11 m-t-xs m-l-sm m-t">
              <div class="user-permissions" v-for="module in model.permissoes">

                <div>
                  <erp-checkbox @click.native="checkChilds(module.childs, module.checked)" class="flex items-center"
                                v-model="module.checked">
                    <label class="no-select">
                      <span class="m-l-xs capitalize">{{module.description}}</span> <span v-if="module.tip"
                                                                                          class="font-thin text-blue-grey-6 font-11">- {{module.tip}}</span>
                    </label>
                  </erp-checkbox>
                </div>

                <div class="level2" v-for="component in module.childs">
                  <erp-checkbox @click.native="checkChilds(component.childs, component.checked)"
                                class="flex items-center" v-model="component.checked">
                    <label class="no-select">
                      <span class="m-l-xs capitalize">{{component.description}}</span> <span v-if="component.tip"
                                                                                             class="font-thin text-blue-grey-6 font-11">- {{component.tip}}</span>
                    </label>
                  </erp-checkbox>

                  <div class="level3" v-for="action in component.childs">
                    <erp-checkbox class="flex items-center" v-model="action.checked">
                      <label class="no-select">
                        <span class="m-l-xs capitalize">{{action.description}}</span> <span v-if="action.tip"
                                                                                            class="font-thin text-blue-grey-6 font-11">- {{action.tip}}</span>
                      </label>
                    </erp-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </e-col>
          <e-col style="max-width: 200px">
            <e-btn :loading="redefinindoSenha" @click="redefineSenha" class="w-full" label="Redefinir senha" />
            <e-btn :loading="redefinindoSenha" @click="digitarSenha" class="w-full m-t-sm" label="Digitar senha" />
            <div v-if="digitarSenhaAction" class="m-t bg-grey-3 wrapper">
              <e-row class="full-width">
                <erp-s-field
                    view="tl"
                    label="Informe a nova senha:"
                >
                  <erp-input type="password" :no-pass-toggle="true" @keydown.enter="confirmarSenha1" @keydown.tab.prevent="confirmarSenha1" v-model="novaSenha.senha" autofocus class="full-width" />
                </erp-s-field>
              </e-row>
              <e-row class="full-width">
                <erp-s-field
                    view="tl"
                    label="Informe a nova senha:"
                    class="m-t-sm"
                    :disabled="!novaSenha.novaSenhaDigitada"
                >
                  <erp-input ref="inputSenha2" type="password" :no-pass-toggle="true" @keydown.enter="redefineSenhaManual" :disable="!novaSenha.novaSenhaDigitada" v-model="novaSenha.confirmacao" class="full-width" />
                </erp-s-field>
              </e-row>
              <e-row class="m-t-sm">
                <e-btn :disable="!novaSenhaConfirmada" :loading="redefinindoSenha" @click="redefineSenhaManual" label="Confirmar alteração" />
              </e-row>
            </div>
          </e-col>
        </e-row>
      </div>
      <div v-if="tabActive === 'logs'" class="wrapper flex col-grow">
        <log :usuario="usuario" type="usuario" />
      </div>
    </window-body>
    <window-footer class="flex content-between">
      <!--<div class="flex items-center justify-center font-11">
        <div class="m-l-xs">
          Ao salvar o leilão ele ficará com status Rascunho. Após validar as informações, altere o
          status para Em breve.
        </div>
      </div>-->
      <div class="inline-block window-actions text-right self-end col-grow">
        <u-btn label="Cancelar" icon="chevron-left" icon-type="fa" icon-style="light" flat no-caps
               @click="$uloc.window.close($root.wid)" />
        <u-btn label="Salvar (F2)" icon="save" icon-type="fa" icon-style="light" flat no-caps
               @click="save"
               v-shortkey="['f2']" @shortkey.native="save" />
      </div>
    </window-footer>
  </window-content>
  <window-success v-else-if="success">
    <div class="text-center">
      <u-icon name="check-circle" color="positive" type="fa" icon-style="solid" class="success-circle-140" />
    </div>
    <h5 class="m-t">Usuário atualizado com sucesso!</h5>
    <div class="text-center m-t">
      <timer-close-window ref="timer" @end="$uloc.window.close($root.wid)" />
    </div>
  </window-success>
  <window-loading :errors="errors" :status="status" v-else />
</template>

<script>
/* eslint-disable */
import {
  WindowContent,
  // WindowHeader,
  WindowBody,
  WindowFooter,
  ErpTabItem,
  ErpTabs,
  ErpSField,
  ErpInput,
  // HelperInputBtn,
  // ErpBox,
  ErpLabel,
  ErpSelect,
  ErpCheckbox,
  ErpBox
} from 'uloc-vue-plugin-erp'
import WindowLoading from '../../../layout/window/Loading'
import TimerCloseWindow from '../../../layout/window/TimerClose'
import WindowSuccess from '../../../layout/window/Success'
import {URadio} from 'uloc-vue'
import {VMoney} from 'v-money'
import {REAL_BRL, convertRealToMoney} from '../../../../utils/money'
import {find, permissoes, resetPassword, update} from '../../../../domain/usuario/services/index'
import DatetimeInput from '../../../../reuse/input/Datetime'
import DefaultAsyncSelect from '../../../../reuse/input/DefaultAsyncSelect'
import ERow from '../../../layout/components/Row'
import ECol from '../../../layout/components/Col'
import ApelidoInput from '../../../arrematante/components/include/ApelidoInput'
import Log from '../../../usuario/components/include/Log'
import TipoPessoaSelect from '../../../pessoa/components/include/TipoPessoaSelect'

let childLevel = []

let mockusuario = {
  id: null,
  username: null,
  email: null,
  person: {
    id: null,
    name: null,
    type: 1
  }
}

export default {
  name: 'UsuarioWindow',
  directives: {money: VMoney},
  props: ['id'],
  data () {
    return {
      money: REAL_BRL,
      tabActive: 'principal',
      loading: !!this.id,
      errors: null,
      success: false,
      status: null,
      usuario: JSON.parse(JSON.stringify(mockusuario)),
      model: {
        admin: false,
        permissoes: []
      },
      redefinindoSenha: false,
      digitarSenhaAction: false,
      novaSenha: {
        senha: null,
        novaSenhaDigitada: false,
        confirmacao: null
      }
    }
  },
  mounted () {
    console.log(this.id)
    this.id && find(this.id)
      .then(response => {
        let data = response.data
        /*if (data.dataEntrada && data.dataEntrada.date) {
          data.dataEntrada = date.formatDate(data.dataEntrada.date, 'DD/MM/YYYY HH:mm')
        }
        if (data.dataSaida && data.dataSaida.date) {
          data.dataSaida = date.formatDate(data.dataSaida.date, 'DD/MM/YYYY HH:mm')
        }*/
        /*if (response.data.tipo !== null) {
          this.$nextTick(() => {

          })
        }*/
        this.permissoes(data.erpAcls)
        let mock = JSON.parse(JSON.stringify(mockusuario))
        this.usuario = Object.assign(mock, data)
        this.$nextTick(() => {
          this.model.admin = this.isAdmin()
          this.loading = false
          this.permissoesToAcl(this.model.permissoes, false, this.usuario.acl)
        })
      })
      .catch((error) => {
        this.$uloc.dialog({
          title: 'Falha ao carregar!',
          color: 'negative',
          message: `Não conseguimos carregar o usuário, entre em contato com o administrador.`
        })
        this.loading = false
        this.errors = error.data
        console.error(error, error.data)
      })
  },
  computed: {
    novaSenhaConfirmada () {
      return this.novaSenha.senha === this.novaSenha.confirmacao
    }
  },
  watch: {},
  methods: {
    isAdmin () {
      if (this.usuario.roles) {
        return this.usuario.roles.includes('ROLE_ADMIN')
      }
      return false
    },
    permissoes (acls) {
      console.log(acls)
      let result = []
      let schema = {
        name: null,
        description: null,
        checked: false,
        childs: [],
        fullAcl: null
      }
      Object.keys(acls).forEach((key) => {
        let acl = acls[key]
        let levels = acl[0].split('/')
        let objectLevel = result
        for (let level = 0; level < levels.length; level++) {
          // Cria os leveis
          if (level === 0) {
          } else {
            objectLevel = objectLevel.childs
          }

          let find = objectLevel.find(e => e.name === levels[level])
          if (!find) {
            let object = JSON.parse(JSON.stringify(schema))
            object.name = levels[level]
            object.description = typeof acl[2] !== 'undefined' ? acl[2][level] : acl[1]
            object.fullAcl = level === (levels.length-1) ? acl[0] : null
            objectLevel.push(object)
            objectLevel = object
          } else{
            objectLevel = find
          }

        }

      })
      console.log(result)
      this.model.permissoes = result
      /* permissoes()
        .then((data) => {
          this.model.permissoes = data
          // console.log(this.permissoesToAcl(data))
        }) */
    },
    changeTab ({tab}) {
      this.tabActive = tab
    },
    checkChilds (child, trueFalse) {
      child.map(item => {
        item.checked = trueFalse
        if (item.childs) {
          this.checkChilds(item.childs, trueFalse)
        }
      })
    },
    permissoesToAcl (permissoes, onlyChecked = false, reverse = null) {
      const _acl = {acl: []}
      permissoes.forEach((module) => {
        console.log(module)
        // let item = permissoes[module]
        this.varreChilds(module.childs, module, _acl, true, onlyChecked, reverse)
      });
      console.log('Permissões to ACL: ', _acl)
      return _acl
    },
    varreChilds (childs, parent, _acl, root = false, onlyChecked = false, reverse = null) {
      let allChecked = false
      if (root) {
        childLevel = []
      }
      if (Array.isArray(childs)) {
        // console.log('Varrendo child ', childs)
        childLevel.push(parent.name)
        let checkeds = 0
        childs.forEach((child) => {
          if (Array.isArray(child.childs) && child.childs.length) {
            let r = this.varreChilds(child.childs, child, _acl, false, onlyChecked, reverse)
            if (r) {
              //# parent.checked = true
            }
          } else {
            console.log('Push full ACL: ', child.fullAcl)
            let _push = child.fullAcl // childLevel.concat([child.name]).join('/')
            let checked = child.checked
            /*let checked = true
            if (onlyChecked) {
              checked = child.checked
            }*/
            if (reverse) {
              if (Array.isArray(reverse)) {
                if (reverse.includes(_push)) {
                  child.checked = true
                  checkeds++
                }
              }
            }
            checked && _acl.acl.push(_push)
          }
        })
        childLevel.pop()
        if (checkeds === childs.length) {
          parent.checked = true
          allChecked = true
        }
      }
      return allChecked
    },
    save () {
      console.log('Save')
      let acl = this.permissoesToAcl(this.model.permissoes, true)
      this.usuario.acl = acl.acl
      console.log('ACL: ', acl)
      this.loading = true
      this.usuario.name = this.usuario.person.name
      if (this.model.admin) {
        this.usuario.roles.push('ROLE_ADMIN')
      } else {
        let findRole = this.usuario.roles.find((role) => {
          return role === 'ROLE_ADMIN'
        })
        if (findRole) {
          this.usuario.roles.splice(this.usuario.roles.indexOf(findRole), 1)
        }
      }
      update(this.usuario.id, this.usuario)
        .then(data => {
          this.success = true
          this.$nextTick(() => {
            this.$refs.timer.start()
            this.$uloc.window.emit(this.$root.wid, 'updated', data)
          })
        })
        .catch(error => {
          this.success = false
          this.errorMessage = error.data.message
          // this.errors = this.systemError(error)
          this.$nextTick(() => {
            this.loading = false
            this.alertApiError(error)
            // this.error = true
          })
        })
    },
    redefineSenha () {
      this.redefinindoSenha = true
      resetPassword(this.usuario.id)
        .then((response) => {
          this.redefinindoSenha = false
          this.$uloc.dialog({
            title: 'Senha redefinida com sucesso!',
            color: 'positive',
            message: 'O usuário recebeu a nova senha por e-mail: ' + response.data.password
          })
        })
        .catch(error => {
          this.redefinindoSenha = false
          console.log(error.data)
          // this.errorMessage = error.data.message
          // this.errors = this.systemError(error)
          this.$nextTick(() => {
            this.loading = false
            this.alertApiError(error)
            // this.error = true
          })
        })
    },
    redefineSenhaManual () {
      if (!this.novaSenhaConfirmada) return
      this.redefinindoSenha = true
      resetPassword(this.usuario.id, this.novaSenha.senha)
        .then((response) => {
          this.redefinindoSenha = false
          this.digitarSenhaAction = false
          this.novaSenha.senha = null
          this.novaSenha.confirmacao = null
          this.novaSenha.novaSenhaDigitada = false
          this.$uloc.dialog({
            title: 'Alteração de senha',
            color: 'positive',
            message: 'Senha redefinida com sucesso!'
          })
        })
        .catch(error => {
          this.redefinindoSenha = false
          console.log(error.data)
          // this.errorMessage = error.data.message
          // this.errors = this.systemError(error)
          this.$nextTick(() => {
            this.loading = false
            this.alertApiError(error)
            // this.error = true
          })
        })
    },
    digitarSenha () {
      this.digitarSenhaAction = true
    },
    confirmarSenha1 () {
      this.novaSenha.novaSenhaDigitada = true
      this.$nextTick(() => {
        this.$refs.inputSenha2.focus()
        // setTimeout(() => {}, 300)
      })
    }
  },
  components: {
    TipoPessoaSelect,
    Log,
    ApelidoInput,
    ECol,
    ERow,
    DefaultAsyncSelect,
    WindowSuccess,
    TimerCloseWindow,
    WindowLoading,
    WindowFooter,
    WindowBody,
    // WindowHeader,
    WindowContent,
    ErpTabs,
    ErpTabItem,
    ErpSField,
    ErpInput,
    // HelperInputBtn,
    // ErpBox,
    ErpLabel,
    // UEditor,
    URadio,
    ErpSelect,
    DatetimeInput,
    ErpCheckbox,
    ErpBox
  }
}
</script>
