<template>
  <div class="user-log">
    <erp-tabs @change="changeTab" :active="tabActive">
      <erp-tab-item name="geral">Geral</erp-tab-item>
      <erp-tab-item name="comunication">Comunicacao</erp-tab-item>
      <erp-tab-item name="access">Acesso</erp-tab-item>
    </erp-tabs>
    <div class="log-tab-content"> <!--  v-show="tabActive === 'geral' || tabActive === 'comunication'" -->
      <div class="flex-abs-hack">
        <div class="wrapper" v-if="loading">
          Carregando...
        </div>
        <div v-else>
          <table>
            <thead>
            <th style="width: 150px !important;">Data</th>
            <th>Operação</th>
            <th>Opções</th>
            </thead>
            <tbody v-if="log && log.result">
            <tr v-for="reg in logData" :key="reg.id">
              <td style="width: 150px !important;">{{reg.data.date|formatDate}}</td>
              <td>
                <log-desc-email v-if="reg.type === 'comunication' && reg.obj.channel === 'email'" :reg="reg" />
                <div v-else>{{reg.descricao}}</div>
              </td>
              <td><a class="text-black">Consultar</a></td>
            </tr>
            </tbody>
            <tbody v-else>
            <tr>
              <td colspan="3">Nenhum registro encontrato</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ErpTabs, ErpTabItem} from 'uloc-vue-plugin-erp'
import {logArrematante} from '../../../../domain/arrematante/services'
import {log as logUsuario} from '../../../../domain/usuario/services'
import LogDescEmail from './LogDescEmail';

export default {
  name: 'Log',
  props: ['usuario', 'type'],
  data () {
    return {
      tabActive: 'geral',
      loading: true,
      log: null,
      filterType: null
    }
  },
  components: {LogDescEmail, ErpTabs, ErpTabItem},
  mounted () {
    this.load()
  },
  computed: {
    logData () {
      let log = this.log.result
      if (!this.filterType) {
        return log
      }
      return log.slice().filter((reg) => {
        return reg.type === this.filterType
      })
    }
  },
  methods: {
    changeTab ({tab}) {
      if (tab === 'geral') {
        this.filterType = null
      } else {
        this.filterType = tab
      }
      this.tabActive = tab
    },
    load () {
      let loader = logUsuario.bind(this, this.usuario.id)
      if (this.type === 'arrematante') {
        loader = logArrematante
      }
      loader()
        .then((response) => {
          this.loading = false
          if (!Array.isArray(response.data.result)) {
            return
          }
          this.log = response.data
        })
        .catch((error) => {
          console.log(error)
          this.loading = false
        })
    }
  }
}
</script>

<style lang="stylus">
  .user-log {
    display flex
    flex 1
    flex-direction column

    .erp-tabs {
      flex 0 !important
    }

    .erp-tabs .erp-tabs-item.active {
      background #E4E4E4 !important
      border-bottom #E4E4E4 !important
    }

    .erp-tabs .erp-tabs-item::after {
      border: #E4E4E4 1px solid;
    }

    .log-tab-content {
      background-color #E4E4E4
      border #9DA0AA 1px solid
      flex 1
      position relative
      min-height 200px
    }

    table {
      width 100%
      padding 18px
      border-spacing 0px

      th {
        border-top none !important
      }

      th, td {
        padding 5px 0
        text-align left
        border-bottom: #C4C4C4 1px solid
        border-top #F2F2F2 1px solid
      }
    }
  }
</style>
