<template>
  <helper-input-btn>
    <erp-input
        @input="__set"
        :placeholder="placeholder || ''"
        v-model="model"
        :loading="loadingSugestao"
        @blur="blur"
        :class="{'bg-positive': checked && aprovado, 'bg-negative': error}"
        :required="required"
        :simple-border="simpleBorder" />
    <a @click="gerarApelido" v-if="gerar" class="m-l-xs text-black font-10 text-nowrap no-select">
      <u-icon name="list-alt" type="fa" class="m-r-xxs" />
      gerar</a>
  </helper-input-btn>
</template>

<script>
import {ErpInput, HelperInputBtn} from 'uloc-vue-plugin-erp'
import InputMixin from '../../../../reuse/input/mixin'
import {verificaApelido} from '../../../../domain/arrematante/services';

export default {
  name: 'ApelidoInput',
  data () {
    return {
      checked: false,
      aprovado: false,
      error: false,
      loadingSugestao: false
    }
  },
  props: {
    gerar: {
      required: false,
      default: true
    },
    gerarBlur: {
      required: false,
      default: true
    },
    nome: {
      required: false
    },
    required: {
      required: false,
      default: false
    },
    simpleBorder: {
      required: false,
      default: true
    }
  },
  methods: {
    gerarApelido () {
      this.verificaApelido(null, this.nome, true)
    },
    blur () {
      this.gerarBlur && this.verificaApelido(this.model, null, false)
    },
    verificaApelido (apelido, nome, sugerir = false) {
      this.checked = false
      this.error = false

      if (this.$cacheCheckApelido && this.$cacheCheckApelido === apelido && !sugerir) {
        this.aprovado = true
        return;
      }

      //Sugere um apelido
      let sugestao
      if (sugerir === true) {
        let _nome = String(nome)
        if (_nome.length < 3) {
          // alert('Não consigo sugerir antes de digitar o nome completo :/');
          // this.$uloc.notify({color: 'positive', position: 'top', message: ''})
          this.$uloc.notify({color: 'negative', position: 'top', message: 'Não consigo sugerir antes de digitar o nome completo :/'})
          // nome.focus();
          this.error = true
          return;
        }
        let listNomes = _nome.split(' ', 2);
        if (listNomes.length < 2) {
          // alert('Preciso do nome completo antes de sugerir um apelido :/');
          this.$uloc.notify({color: 'negative', position: 'top', message: 'Preciso do nome completo antes de sugerir um apelido :/'})
          // nome.focus();
          this.error = true
          return;
        }

        sugestao = listNomes.join('').toLowerCase();
        // remove accents, swap ñ for n, etc
        let from = 'àáäâèéëêìíïîòóöôùúüûñçãõê';
        let to = 'aaaaeeeeiiiioooouuuuncaoe';
        for (let i = 0, l = from.length; i < l; i++) {
          sugestao = sugestao.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
        }
        sugestao = sugestao.toUpperCase();
      } else {
        sugestao = !apelido ? '' : String(apelido)
      }

      if (sugestao.length < 3) {
        // alert('O apelido deve conter no mínimo três caracteres.')
        this.$uloc.notify({color: 'negative', position: 'top', message: 'O apelido deve conter no mínimo três caracteres.'})
        this.error = true
        return;
      }

      let cloneSugestao = sugestao;

      /* let load = apelido.parent().find('.sugestao-load');
      let divSugestao = apelido.parent().find('.apelido-sugestao');
      load.removeClass('hide');
      divSugestao.addClass('hide');
      apelido.removeClass('aprovado'); */
      this.loadingSugestao = true
      this.aprovado = false
      verificaApelido(sugestao)
        .then(({data}) => {
          if (!data.disponivel) {
            sugestao = data.sugestao.toUpperCase();
          }
          if (sugerir === false && cloneSugestao !== sugestao) {
            // alert('O apelido ' + cloneSugestao + ' não está disponível, sugerimos um parecido: ' + sugestao)
            this.$uloc.dialog({
              title: 'Apelido não disponível!',
              color: 'negative',
              message: 'O apelido ' + cloneSugestao + ' não está disponível, sugerimos um parecido: ' + sugestao
            })
          }
          this.__emit(sugestao)
          console.log(data)
          this.loadingSugestao = false
          this.checked = true
          this.aprovado = true
          this.$cacheCheckApelido = sugestao
        })
        .catch(error => {
          console.log(error)
        })
      /* $.get(urlVerificaApelido.replace('__APELIDO__', sugestao), function (data) {
        if (!data.disponivel) {
          sugestao = data.sugestao.toUpperCase();
        }
        if (sugerir === false && cloneSugestao !== sugestao) {
          alert('O apelido ' + cloneSugestao + ' não está disponível, sugerimos um parecido para você: ' + sugestao);
        }
        apelido.val(sugestao);
        load.addClass('hide');
        divSugestao.removeClass('hide');
        apelido.keyup();
        apelido.addClass('aprovado');
        cacheCheckApelido = sugestao;
      })
        .fail(function (error) {
          alert('Ocorreu algum problema ao acessar a aplicação. Tente novamente, caso o erro persista contate a equipe de suporte.')
          console.log(error)
        }); */
    }
  },
  components: {ErpInput, HelperInputBtn},
  mixins: [InputMixin]
}
</script>
