<template>
  <div>
    {{reg.descricao}} "<a>{{reg.obj.descricao}}</a>" <u-icon name="envelope" type="fa" icon-style="light" />
  </div>
</template>

<script>
export default {
  name: 'LogDescEmail',
  props: {
    reg: {required: true}
  }
}
</script>

<style scoped>

</style>
